<template>
  <b-card class="shop-order-history">
    <div class="row">
      <div class="col-md-3">
        <label>{{ $t('Order Type') }}</label>
        <b-form-select v-model="filterDates.type" :options="orderTypeOptions" />
      </div>
      <div class="col-md-3">
        <label for="datepicker-default">{{ $t('Date From') }}</label>
        <b-form-datepicker v-model="filterDates.stateDate" local="en" class="mb-2" today-variant="danger" />
      </div>
      <div class="col-md-3">
        <label for="datepicker-default">{{ $t('Date To') }}</label>
        <b-form-datepicker v-model="filterDates.endDate" local="en" class="mb-2" today-variant="danger" />
      </div>
      <div class="col-md-3">
        <label>{{ $t('Status') }}</label>
        <b-form-select v-model="filterDates.status" :options="statusList" />
      </div>
      <div class="col-md-3">
        <label>{{ $t('Payment Status') }}</label>
        <b-form-select v-model="filterDates.paid" :options="paidList" />
      </div>
      <div class="col-md-3">
        <label>{{ $t('Payment Mode') }}</label>
        <b-form-select v-model="filterDates.payment_mode" :options="paymentModeList" />
      </div>
      <div class="col-md-3 text-left d-flex flex-wrap align-items-center mb-1">
        <button class="btn btn-primary mt-2" @click="filterData">
          {{ $t('Submit') }}
        </button>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <good-data-table ref="orderRef" :columns="orderColumns" :no-serial="true"
          :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint()" />
      </b-col>
    </b-row>
    <b-modal id="orderHistoryShop" v-model="showOrderHistory" :no-close-on-backdrop="true" :title="$t('Order History')"
      size="xl" ok-only @hidden="showOrderHistory = false">
      <order-history-detail :request-hisory="requestHisory" />
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable global-require */

import { BRow, BCol, BCard, BModal, BFormDatepicker, BFormSelect } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import moment from 'moment'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import OrderHistoryDetail from '@/views/shared/OrderHistoryDetail.vue'
import { useStoreUi } from './useStore'
import { isEmpty } from '@/utilities'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    OrderHistoryDetail,
    BCard,
    BModal,
    BFormDatepicker,
    BFormSelect,
  },
  data() {
    return {
      filterDates: {},
      requestHisory: null,
      showOrderHistory: false,
      statusImages: {
        DELIVERY: require('@/assets/images/app-icons/agent.png'),
        WALLET: require('@/assets/images/app-icons/wallet.png'),
        'DINE IN': require('@/assets/images/app-icons/shop.png'),
        DINE_IN: require('@/assets/images/app-icons/shop.png'),
        POS: require('@/assets/images/app-icons/POS.jpeg'),
        TAKEAWAY: require('@/assets/images/app-icons/take-away.png'),
        CASH: this.$helpers.getIcons('cash'),
        CARD: this.$helpers.getIcons('card'),
        'CARD ON DELIVERY': this.$helpers.getIcons('card-on-delivery'),
        CARD_ON_DELIVERY: this.$helpers.getIcons('card-on-delivery'),
        on_delivery: this.$helpers.getIcons('card-on-delivery'),
      },
      orderColumns: [
        {
          label: 'Booking Id',
          field: 'store_order_invoice_id',
        },
        {
          label: 'Date',
          field: 'assigned_time',
        },
        {
          label: 'Customer',
          field: 'user.first_name',
          resolvePath: 'user.first_name',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => {
            if (props.user) {
              return props.order_type === 'POS' ? props.user.first_name : `${props.user.first_name} ${props.user.last_name}`
            }
            return ''
          },
          renderer: props => {
            if (props.user) {
              return props.order_type === 'POS' ? props.user.first_name : `${props.user.first_name} ${props.user.last_name}`
            }
            return ''
          },
        },
        {
          label: 'Amount',
          field: 'total_amount',
          rField: 'total_amount_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.total_amount ? `${props.currency} ${props.total_amount}` : 'N/A'),
        },
        {
          label: 'Type',
          field: 'order_type',
          rField: 'order_type_text',
          useResolver: true,
          useHtml: true,
          textRenderer: props => window.SuperApp.actions.replaceUnderscore(props.order_type),
          renderer: props => {
            if (props.order_type == 'DIGITAL') {
              return `<div class="d-flex align-items-center justify-content-center text-center wrap-dashboard-card-options width-fc custom-icons">
                  <img src="${this.$apiUrl}/images/icons/digital.png" />
                  <p class="mb-0 font-12">${window.SuperApp.actions.replaceUnderscore(props.order_type)}</p>
              </div>`
            } else {
              return `<div class="d-flex align-items-center justify-content-center text-center wrap-dashboard-card-options width-fc custom-icons">
                  <img src="${this.statusImages[props.order_type]}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />
                  <p class="mb-0 font-12">${window.SuperApp.actions.replaceUnderscore(props.order_type)}</p>
              </div>`
            }
          },
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => props.status.replaceAll('_',' ').toUpperCase(),
          renderer: props => props.status.replaceAll('_',' ').toUpperCase(),
        },
        {
          label: 'Agent',
          field: 'agent_name',
          resolvePath: 'agent.first_name',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => {
            if (props.agent) {
              return `${props.agent.first_name} ${props.agent.last_name}`
            }
            return ''
          },
          renderer: props => {
            if (props.agent) {
              return `${props.agent.first_name} ${props.agent.last_name}`
            }
            return ''
          },
        },
        {
          label: 'Pay Mode',
          field: 'paymentmode',
          useResolver: true,
          useRenderer: true,
          useHtml: true,
          excelHtml: props => props.payment_mode ? props.payment_mode.replaceAll('_', ' ').toUpperCase() : null,
          renderer: props => {
            return `<img style="width: 30px; height: 30px;" src="${this.statusImages[props.payment_mode]}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />`
          }
        },
        {
          label: 'Pay Status',
          field: 'paid',
          rField: 'paid_text',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            // eslint-disable-next-line eqeqeq
            if (props.paid == 1) {
              return 'Paid'
            }
            return 'Not Paid'
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          buttons: [
            {
              icon: 'download-invoice',
              title: 'Download Invoice',
              image: require('@/assets/images/app-icons/document.png'),
              action: props => {
                if (props.invoice && props.invoice.invoice_url) {
                  window.open(window.SuperApp.helper.image(props.invoice.invoice_url))
                }
              },
            },
          ],
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewOrder(props)
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
    statusList() {
      return [
        ...[
          {
            text: 'All',
            value: '',
          },
        ],
        ...['ACCEPTED', 'ASSIGNED', 'PICKEDUP', 'COMPLETED', 'CANCELLED'].map(x => ({
          text: x,
          value: x,
        })),
      ]
    },
    paidList() {
      return [
        {
          text: 'Select Paid Status',
          value: '',
        },
        {
          text: 'Paid',
          value: '1',
        },
        {
          text: 'Not Paid',
          value: '0',
        },
      ]
    },
    orderTypeOptions() {
      return [
        {
          text: 'Select Order Type',
          value: '',
        },
        {
          text: 'DELIVERY',
          value: 'DELIVERY',
        },
        {
          text: 'PICKUP',
          value: 'TAKEAWAY',
        },
        {
          text: 'DINE IN',
          value: 'DINE_IN',
        },
        {
          text: 'POS',
          value: 'POS',
        },
      ]
    },
    paymentModeList() {
      return [
        {
          text: 'Select Payment Mode',
          value: '',
        },
        {
          text: 'Card',
          value: 'CARD',
        },
        {
          text: 'Cash',
          value: 'CASH',
        },
        {
          text: 'Card on Delivery',
          value: 'CARD_ON_DELIVERY',
        },
      ]
    },
  },
  methods: {
    filterData() {
      const payload = []
      if (this.filterDates.stateDate) payload.push(`from=${moment(this.filterDates.stateDate).format('YYYY-MM-DD')}`)
      if (this.filterDates.endDate) payload.push(`to=${moment(this.filterDates.endDate).format('YYYY-MM-DD')}`)
      if (this.filterDates.status) payload.push(`status=${this.filterDates.status}`)
      if (this.filterDates.type) payload.push(`type=${this.filterDates.type}`)
      if (this.filterDates.paid) payload.push(`paid=${this.filterDates.paid}`)
      if (this.filterDates.payment_mode) payload.push(`payment_mode=${this.filterDates.payment_mode}`)
      this.$refs.orderRef.loadItems(payload.join('&'))
    },
    getApiEndpoint() {
      return '/shop/shoprequesthistory'
    },
    viewOrder(props) {
      const { shopRequestHistory } = useStoreUi()
      showLoader()
      shopRequestHistory(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.requestHisory = data.responseData
            this.showOrderHistory = true
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
          hideLoader()
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
#orderHistoryShop .modal-body {
  max-height: 75vh;
  overflow-y: auto;
}
</style>
